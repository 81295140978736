import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PublicConf } from 'src/environments/public-conf.models';

@Injectable({
  providedIn: 'root',
})
export class AppConfig {
  private conf: PublicConf | undefined;

  constructor(private http: HttpClient) {}

  get config() {
    return this.conf;
  }

  get(key: string): any {
    return _.get(this.conf, key);
  }

  getAsString(key: string): string {
    return this.get(key);
  }

  set(key: string, value: any): void {
    return _.set(this.conf, key, value);
  }

  public load() {
    return new Promise<void>((resolve) => {
      this.http.get<PublicConf>('./assets/config/json/config.json').subscribe((config) => {
        this.conf = config;
        // Convert the 'production' field to a boolean
        this.conf.production = (this.conf.production as unknown as string).toLowerCase() === 'true';

        const packageJson = require('../../../../package.json');
        this.conf.app = {
          ...this.config.app,
          version: packageJson.version,
        };

        resolve();
      });
    });
  }
}
