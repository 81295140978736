import { Component, Input, OnChanges } from '@angular/core';
import { DeliveryPoint, EliqAccessRights } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import {
  EnergyType,
  SelfConsumptionRequestData,
} from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { Site } from '@app/modules/customer-zone/user/models/site.interface';
import { MainFacade } from '@app/core/facade/main.facade';
import moment from 'moment';
import { catchError, map, of, throwError } from 'rxjs';

@Component({
  selector: 'app-forecast-injection-widget',
  templateUrl: './forecast-injection-widget.component.html',
  styleUrls: ['./forecast-injection-widget.component.scss'],
})
export class ForecastInjectionWidgetComponent implements OnChanges {
  @Input() eliqAccessRights: EliqAccessRights;
  @Input() reference: string;
  @Input() site: Site;
  @Input() deliveryPoints: DeliveryPoint[];
  @Input() energyType: EnergyType;
  data$: any;
  show = true;
  error = false;
  isFirstDayOfTheMonth = moment().startOf('day').isSame(moment().startOf('month'));
  monthDate = this.isFirstDayOfTheMonth
    ? moment().startOf('month').subtract(1, 'months').toDate()
    : moment().startOf('month').toDate();
  protected readonly Math = Math;

  constructor(public facade: MainFacade) {}

  ngOnChanges(data): void {
    if (data?.deliveryPoints?.currentValue?.length) {
      this.error = false;
      const deliveryPoint: DeliveryPoint = this.deliveryPoints.find(({ energy }) => energy === this.energyType);
      const deliveryPointAccess = this.eliqAccessRights?.widgetAccessPerDeliveryPointReference?.find(
        ({ deliveryPointReference }) => deliveryPointReference === deliveryPoint?.reference
      );
      //this.show = deliveryPointAccess?.access?.seeWidgetConsumptionOrInjectionChartMeasurementAndEstimateFuture;
      //if (this.show) {
      const selfConsumptionRequestData: SelfConsumptionRequestData = {
        reference: this.reference,
        siteId: this.site.id,
        energyType: this.energyType,
        deliveryPoint: deliveryPoint.reference,
        fromDate: this.isFirstDayOfTheMonth
          ? moment().startOf('month').subtract(1, 'months').toDate()
          : moment().startOf('month').toDate(),
        toDate: this.isFirstDayOfTheMonth
          ? moment().startOf('month').toDate()
          : moment().startOf('month').add(1, 'months').toDate(),
      };
      this.data$ = this.facade.loadSelfConsumption(selfConsumptionRequestData).pipe(
        map((data) => {
          if (data.length === 0) {
            this.error = true;
          }

          return data;
        }),
        catchError((error) => {
          if (error.status === 428) {
            return of({});
          }
          this.error = true;
          return throwError(error);
        })
      );
    }
    //}
  }
}
