import { Component, Input, OnInit } from '@angular/core';
import { EliqAccessRights } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { MainFacade } from '@app/core/facade/main.facade';
import { AppConfig } from '@app/shared/utils/app-config';
import { HttpClient } from '@angular/common/http';
import { MandatesService } from '@app/modules/customer-zone/consumption/services/mandates/mandates.service';

@Component({
  selector: 'app-fluvius-mandates-cta',
  templateUrl: './fluvius-mandates-cta.component.html',
  styleUrls: ['./fluvius-mandates-cta.component.scss'],
})
export class FluviusMandatesCtaComponent implements OnInit {
  @Input() eliqAccessRights: EliqAccessRights;
  fluviusLinksForEans: { [ean: string]: string } = {};

  constructor(
    public mandatesService: MandatesService,
    public facade: MainFacade,
    public conf: AppConfig,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    Object.keys(this.eliqAccessRights.profileByDeliveryPointReference).forEach((ean: string) => {
      this.facade.getShortLinkMandateActivation(null, ean, null).subscribe((response) => {
        this.fluviusLinksForEans[ean] = response.fluviusUrl;
      });
    });
  }

  protected readonly Object = Object;
}
