import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, shareReplay } from 'rxjs';
import { ActivateMandateBody, ActivateMandateResponse, Mandate } from '../../models/mandates.interface';

@Injectable({
  providedIn: 'root',
})
export class MandatesService {
  private mandatesStore: Record<string, Mandate[]> = {};

  private mandates$: BehaviorSubject<Mandate[]> = new BehaviorSubject(null);

  constructor(private http: HttpClient) {}

  getMandates$(): Observable<any> {
    return this.mandates$.asObservable();
  }

  public getMandates(reference: string): Observable<Mandate[]> {
    if (this.mandatesStore[reference]) {
      this.mandates$.next(this.mandatesStore[reference]);
    } else {
      this.getMandatesFromApi(reference);
    }
    return this.mandates$.asObservable();
  }

  getMandatesFromApi(reference: string) {
    return this.http
      .get(`/v1/customers/${reference}/mandates`)
      .subscribe((value: Mandate[]) => this.mandates$.next(value));
  }

  public postActivateMandateShortlink(
    customerReference: string,
    data: ActivateMandateBody
  ): Observable<ActivateMandateResponse> {
    return this.http
      .post<ActivateMandateResponse>(`/v1/customers/${customerReference}/short-link`, data)
      .pipe(shareReplay(1));
  }

  async createMockMandate(createMockMandateUrl: string) {
    this.http.post(createMockMandateUrl, null).subscribe((response: Mandate) => {
      this.mandates$.next([...this.mandates$.value, response]);
    });
  }

  async createAllMockMandates(createMockMandateUrl: string) {
    this.http.post(createMockMandateUrl, null).subscribe((response: Mandate[]) => {});
  }
}
