<h3 class="text-2xl leading-tight mb-8 md:mb-16">
  {{ 'components.fluviusConsents.intro.infoTitle' | translate }}
</h3>
<div class="md:flex gap-8">
  <div class="relative bg-grey-light rounded-xl p-8 flex items-start mt-8 sm:mt-0"
       *ngFor="let eanKey of Object.keys(this.eliqAccessRights.profileByDeliveryPointReference)">
    <img [ngSrc]="'assets/img/icons/standalone/red/' + this.eliqAccessRights.profileByDeliveryPointReference[eanKey].energyType.toLocaleLowerCase() + '.svg'" width="24" height="24" class="mr-8"
         alt="electricity" />
    <div class="flex-1">
      <h4
        class="text-base font-normal"
        [innerHTML]="'components.fluviusConsents.intro.mandates.cta.energy.' + this.eliqAccessRights.profileByDeliveryPointReference[eanKey].energyType.toLocaleLowerCase() | translate"
      >
      </h4>
      <ng-container [ngTemplateOutlet]="this.conf.config.production ? fluviusLink : mockMandateInTest" [ngTemplateOutletContext]="{ fluviusUrl: fluviusLinksForEans[eanKey], mockMandateUrl: fluviusLinksForEans[eanKey] }"></ng-container>
    </div>
  </div>
</div>

<ng-template #fluviusLink let-fluviusUrl="fluviusUrl">
  <a
    class="absolute top-0 left-0 w-full h-full cursor-pointer"
    [href]="fluviusUrl"
    target="_blank">
  </a>
</ng-template>

<ng-template #mockMandateInTest let-mockMandateUrl="mockMandateUrl">
  <form
    class="absolute top-0 left-0 w-full h-full cursor-pointer"
    (ngSubmit)="this.facade.createMockMandate(mockMandateUrl)"
    method="post">
    <button class="w-full h-full" type="submit"></button>
  </form>
</ng-template>
